import { Fragment } from 'react';
import { useCallback } from 'react';
import { Flex, Box, Text, Badge } from '@chakra-ui/react';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { useSetAtom, useAtomValue } from 'jotai';
import {
  filterGeoLocationAtom,
  filterCacheGeoLocationAtom,
} from '@/atoms/filter';
import Image from 'next/image';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

const BuildingItem = ({ data }) => {
  const searchParams = useSearchParams();
  const queryString = searchParams.toString();
  const setFilterGeoLocation = useSetAtom(filterGeoLocationAtom);
  const filterCacheGeoLocation = useAtomValue(filterCacheGeoLocationAtom);

  const shortBuildingId =
    data?.id?.length === 36 ? data?.id.substring(0, 8) : data?.id;
  const hasProducts = data?.subscription_products?.some(
    (p) => !p.is_sold && !p.is_contact_support,
  );
  const isProvideWaitlist = Boolean(data?.subscription_solution?.fully_booked);

  const renderBullet = useCallback((_, className) => {
    return `<span class="${className}">${' '}</span>`;
  }, []);

  const handleClickItem = () => {
    setFilterGeoLocation(filterCacheGeoLocation);
  };

  return (
    <>
      <Swiper
        pagination={{
          clickable: true,
          renderBullet,
        }}
        modules={[Pagination]}
        slidesPerView={1}
        autoHeight={true}>
        {data.spaces.length === 0 && (
          <Box
            w='100%'
            minH={180}
            bg='gray.100'
            borderRadius={8}
            overflow='hidden'
            cursor='grab'>
            <a
              href={`/b/${shortBuildingId}${
                queryString ? `?${queryString}` : ''
              }`}
              onClick={handleClickItem}
              target='_blank'>
              <Box pos='relative' w='100%' h='100%'>
                <Image
                  src='/placeholder.jpg'
                  alt='無此停車場圖片'
                  width={240}
                  height={180}
                  style={{
                    borderRadius: 8,
                    objectFit: 'cover',
                    width: '100%',
                    height: 'auto',
                    aspectRatio: 240 / 180,
                  }}
                />
              </Box>
            </a>
          </Box>
        )}
        {data.spaces
          .slice(Math.max(data?.spaces.length - 1, 0))
          .map(({ id, name, url, entry_url }, index) => (
            <Fragment key={`buidling-item-swiper-slide-${id}`}>
              <SwiperSlide>
                <Box
                  w='100%'
                  h={180}
                  bg='gray.100'
                  borderRadius={8}
                  overflow='hidden'>
                  {entry_url && (
                    <a
                      href={`/b/${shortBuildingId}${
                        queryString ? `?${queryString}` : ''
                      }`}
                      target='_blank'
                      data-id={entry_url}
                      data-index={id}
                      onClick={handleClickItem}>
                      <Box pos='relative' w='100%' h='100%' overflow='hidden'>
                        <Image
                          src={entry_url}
                          fill
                          sizes='100% 100%'
                          alt={name}
                          priority={index === 0}
                          style={{ borderRadius: 8, objectFit: 'cover' }}
                        />
                      </Box>
                    </a>
                  )}
                </Box>
              </SwiperSlide>
              <SwiperSlide>
                <Box
                  w='100%'
                  h={180}
                  bg='gray.100'
                  borderRadius={8}
                  overflow='hidden'>
                  {url && (
                    <a
                      href={`/b/${shortBuildingId}${
                        queryString ? `?${queryString}` : ''
                      }`}
                      target='_blank'
                      onClick={handleClickItem}>
                      <Box pos='relative' w='100%' h='100%'>
                        <Image
                          src={url}
                          fill
                          sizes='100% 100%'
                          alt={name}
                          style={{ borderRadius: 8, objectFit: 'cover' }}
                        />
                      </Box>
                    </a>
                  )}
                </Box>
              </SwiperSlide>
            </Fragment>
          ))}
      </Swiper>
      <Flex direction='column'>
        <a
          href={`/b/${shortBuildingId}${queryString ? `?${queryString}` : ''}`}
          onClick={handleClickItem}
          target='_blank'>
          <Flex alignItems='center'>
            {isProvideWaitlist && (
              <Badge colorScheme='red' mr={2} mt={2}>
                預約候補
              </Badge>
            )}
            <Text fontWeight={500} fontSize={16} mt={2}>
              {data.name}
            </Text>
          </Flex>

          {/* <Text fontSize={14} color='primary.gray' noOfLines={2}>
            {data.tags.map(({ name }) => name).join(', ')}
          </Text> */}

          {!data?.is_subscription_productify && data?.subscription_solution && (
            <Text fontSize={14} color='primary.gray' noOfLines={2}>
              {data.subscription_solution.description}
            </Text>
          )}

          <Flex>
            {data?.min_price && (
              <>
                <Text>${data.min_price}</Text>
                <Text color='primary.gray' mx='2'>
                  TWD/月起
                </Text>
              </>
            )}
          </Flex>
        </a>
      </Flex>
    </>
  );
};

export default BuildingItem;
