import { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

const createOverlay = (container, position) => {
  class MapOverlay extends window.google.maps.OverlayView {
    container = null;
    position = null;

    constructor(container, position) {
      super();
      this.container = container;
      this.position = position;
    }

    getPosition() {
      return new window.google.maps.LatLng(
        this.position.lat,
        this.position.lng,
      );
    }

    getVisible() {
      return true;
    }

    onAdd() {
      this.getPanes().floatPane.appendChild(this.container);
    }

    onRemove() {
      if (this.container.parentNode) {
        this.container.parentNode.removeChild(this.container);
      }
    }

    draw() {
      const projection = this.getProjection();
      // const zoom = projection.map.zoom;
      // const sw = projection.fromLatLngToDivPixel(
      //   this.getMap().getBounds().getSouthWest(),
      // );
      // const ne = projection.fromLatLngToDivPixel(
      //   this.getMap().getBounds().getNorthEast(),
      // );

      const position = this.getPosition();
      const point = projection.fromLatLngToDivPixel(position);
      const offsetX = 15;
      const offsetY = 45;

      if (point === null) {
        return;
      }

      this.container.style.transform = `translate(${point.x - offsetX}px, ${
        point.y - offsetY
      }px)`;
    }
  }
  return new MapOverlay(container, position);
};
/**
 * Ref: https://developers.google.com/maps/documentation/javascript/customoverlays?hl=zh-tw
 * 1. 建立一個 div `container` 元素
 * 2. 建立一個 MapOverlay 物件，並傳入上面建立的 div 元素，div 元素會被加到地圖上。
 * 3. 使用 MapOverlay 物件的 setMap() 方法傳入的參數設為地圖物件，這樣就會將 div 元素加到地圖上
 */
const MapOverlayView = ({ map, cluster, position, children, active }) => {
  const container = useMemo(() => {
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.zIndex = active ? 100 : 1;
    return div;
  }, [active]);
  const overlay = useMemo(() => {
    return createOverlay(container, position);
  }, [container, position]);

  useEffect(() => {
    overlay?.setMap(map);
    cluster?.addMarker(overlay);
    return () => {
      overlay?.setMap(null);
      cluster?.removeMarker(overlay);
    };
  }, [map, overlay, cluster, active]);

  return createPortal(children, container);
};

export default MapOverlayView;
