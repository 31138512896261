import { buildingsAtom } from '@/atoms/buildings';
import WithOneTimeToken from '@/components/hoc/withOneTimeToken';
import Main from '@/components/pages/home/Main';
import Layout from '@/components/shared/Layout';
import { NavbarWithFilter as Navbar } from '@/components/shared/Navbar';
import { useSession } from '@/hooks/useSession';
import api from '@/services/apis';
import { Flex, Spinner, Text, Button, Box } from '@chakra-ui/react';
import { useSetAtom } from 'jotai';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';

const CanRePayWarning = () => {
  const router = useRouter();
  const { accessToken } = useSession();
  const [subscriptions, setSubscriptions] = useState([]);
  useEffect(() => {
    if (accessToken) {
      const fetchSubscriptionOrders = async () => {
        const response = await api.fetchSubscriptionOrders({
          accessToken,
        });
        if (response?.error) {
          return;
        }
        setSubscriptions(response.content);
      };
      fetchSubscriptionOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);
  const canRePayOrders = subscriptions?.filter((subscription) => {
    const { subscription_orders: orders } = subscription;
    const _canRePayOrders = orders.filter((order) => {
      const isAllowPaymentMethods =
        order.payment_status === 'unpaid' ||
        order.payment_status === 'payment_failed';
      const isExpired = order?.expired_at
        ? new Date(`${order.expired_at}Z`) < new Date()
        : true;
      return !isExpired && isAllowPaymentMethods;
    });
    return _canRePayOrders.length > 0;
  });

  const isShow = canRePayOrders?.length > 0;  

  return isShow && (
    <Flex
      pos='fixed'
      top={{ base: '126px', lg: '76px' }}
      left='0'
      right='0'
      mx='auto'
      bg='rgba(255, 242, 242, 1)'
      borderRadius='4px'
      borderWidth='1px'
      borderStyle='solid'
      px='16px'
      borderColor='rgba(255, 179, 175, 1)'
      color='red.500'
      maxW='472px'
      h='56px'
      w='calc(100% - 32px)'
      alignItems='center'
      justifyContent='space-between'
      zIndex='50'
      cursor='pointer'
      onClick={() => {
        router.push('/account/orders');
      }}>
      <Text fontWeight={900}>你有 {canRePayOrders?.length} 筆訂單尚未付款！</Text>
      <Flex alignItems='center'>
        <Flex p={0} color='red.500'>
          <Text fontSize='14px' fontWeight='500'>
            立即前往
          </Text>
          <Box mx={1}>
            <MdKeyboardArrowRight size='24px' />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

function Home() {
  const setBuildings = useSetAtom(buildingsAtom);
  const [isLoading, setIsLoading] = useState(true);

  const router = useRouter();
  const redirect = router.query?.redirect;

  useEffect(() => {
    if (redirect) {
      router.push(redirect);
    }
  }, [redirect, router]);

  useEffect(() => {
    const fetchBuildings = async () => {
      const response = await api.fetchBuildings();
      if (response?.error) {
        return;
      }
      setBuildings(response.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    };
    fetchBuildings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Flex direction='column' minH='100vh'>
        <Navbar />

        <Flex flex='1' pos='relative' mt='px'>
          {isLoading && (
            <Flex
              pos='absolute'
              top='0'
              left='0'
              w='100%'
              h='100%'
              alignItems='center'
              zIndex='1040'
              justifyContent='center'
              bg='blackAlpha.800'>
              <Spinner color='white' />
            </Flex>
          )}
          <CanRePayWarning />
          <Main />
        </Flex>
      </Flex>
    </Layout>
  );
}

export default WithOneTimeToken(Home);
