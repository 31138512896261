import { useCallback, useEffect, Fragment } from 'react';
import Cookie from 'js-cookie';
import { useAtom, useSetAtom, useAtomValue } from 'jotai';
import { LiveChatWidget, useWidgetState } from '@livechat/widget-react';
import { useSession } from '@/hooks/useSession';
import {
  liveChatSessionVariablesAtom,
  liveChatSessionVariablesMessageAtom,
  liveChatVisibilityAtom,
} from '@/atoms/livechat';
import { useRouter } from 'next/router';
import { userAtom, clearUserAtom } from '@/atoms/user';
import { useFlashMessage } from '@/hooks/useFlashMessage';
import { sendAppierIdentify, sendAppierEvent } from '@/utils/appier';
import { format, isSameDay, parseISO } from 'date-fns';

import api from '@/services/apis';

const Layout = ({ children }) => {
  const session = useSession();
  const [user, setUser] = useAtom(userAtom);
  const clearUser = useSetAtom(clearUserAtom);

  const appierRegistrationCompletedEvent =
    Cookie.get('appierRegistrationCompletedEvent') === 'true';
  const appierLoginEvent = Cookie.get('appierLoginEvent') === 'true';

  const router = useRouter();

  const [visibility, setVisibility] = useAtom(liveChatVisibilityAtom);
  const liveChatState = useWidgetState();
  const [sessionVariables, setSessionVariables] = useAtom(
    liveChatSessionVariablesAtom,
  );
  const message = useAtomValue(liveChatSessionVariablesMessageAtom);

  // 同步 Widget 狀態
  // useEffect(() => {
  //   if (liveChatState?.visibility) {
  //     setVisibility(liveChatState.visibility);
  //   }
  // }, [liveChatState, setVisibility]);

  const fetchUser = useCallback(async ({ accessToken }) => {
    const response = await api.fetchMe({
      accessToken,
    });
    if (response.error) {
      clearUser();
      session.signOut();
      return;
    }

    setUser({
      ...response.content,
    });
    // 同步 Appier Profile
    const isNewUser = isSameDay(
      parseISO(response?.content?.created_at),
      new Date(),
    );

    if (isNewUser) {
      // Appier Profile 註冊
      if (!appierRegistrationCompletedEvent) {
        sendAppierIdentify({
          user_id: response?.content?.id,
          phoneNo: response?.content?.phone,
          email: response?.content?.email,
          member_plates: response?.content?.car_number,
          user_name: response?.content?.fullname,
          agreements_date: new Date(response?.content?.agreed_policy_at),
          registration_platform: 'upass',
          profile_update_date: new Date().getTime(),
        });
        // Appier Event 完成註冊
        sendAppierEvent('registration_completed', {
          user_id: response?.content?.id,
          phoneNo: response?.content?.phone,
          registration_location: null,
          created_at: response?.content?.created_at,
        });
        Cookie.set('appierRegistrationCompletedEvent', 'true', {
          secure: true,
          sameSite: 'lax',
          expires: 1,
        });
      }
    } else {
      // Appier Profile 登入
      if (!appierLoginEvent) {
        sendAppierIdentify({
          user_id: response?.content?.id,
          phoneNo: response?.content?.phone,
          email: response?.content?.email,
          user_name: response?.content?.fullname,
          agreements_date: new Date(response?.content?.agreed_policy_at),
          profile_update_date: new Date().getTime(),
        });
        // Appier Event 登入
        sendAppierEvent('login', {
          user_id: response?.content?.id,
          phoneNo: response?.content?.phone,
          profile_update_date: new Date().getTime(),
        });
        if (response?.content?.payment_method) {
          // Appier Profile 付款資訊
          sendAppierIdentify({
            user_id: response?.content?.id,
            phoneNo: response?.content?.phone,
            email: response?.content?.email,
            payment_method1: response?.content?.payment_method,
          });
        }
        Cookie.set('appierLoginEvent', 'true', {
          secure: true,
          sameSite: 'lax',
          expires: 1,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isSignedIn = Boolean(user.id);
    if (session.accessToken && !isSignedIn) {
      Cookie.set('accessToken', session.accessToken, {
        secure: true,
        sameSite: 'lax',
      });
      fetchUser({
        accessToken: session.accessToken,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.accessToken, user.id]);

  const { fullname, email, phone } = user;
  useEffect(() => {
    if (!phone) {
      return;
    }
    let vars = {
      name: fullname ? fullname : '會員',
      email: email ? email : '無資料',
      phone: phone ? phone : '無資料',
    };
    if (message) {
      vars = {
        ...vars,
        message: decodeURIComponent(message),
      };
    }
    setSessionVariables(vars);
  }, [message, setSessionVariables, fullname, email, phone]);

  const flash = useFlashMessage();
  useEffect(() => {
    flash.consume();
  }, [flash]);

  return (
    <Fragment key={session.accessToken}>
      {children}
      {/* <LiveChatWidget
        group='4'
        license='14868420'
        visibility={visibility}
        customerName={user.fullname ? user.fullname : '會員'}
        customerEmail={user.email ? user.email : '無資料'}
        sessionVariables={sessionVariables}
      /> */}
    </Fragment>
  );
};

export default Layout;
